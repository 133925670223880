<template>
  <div>
    <v-row class="mt-1 ml-2 mb-3">
      <h2 class=" primary--text">Additions</h2>
    </v-row>
    <template>
      <v-tabs background-color="transparent" v-model="tabIndex">
        <v-tab @click="switchTab('bonus')">BONUS</v-tab>
        <v-tab @click="switchTab('allowance')">ALLOWANCE</v-tab>
      </v-tabs>
    </template>
    <template>
      <bonus class="mt-10" v-if="currentTab === 'bonus'"></bonus>
      <allowance class="mt-10" v-if="currentTab === 'allowance'"></allowance>
    </template>
  </div>
</template>

<script>
import Bonus from '@/components/Bonus.vue';
import Allowance from '@/components/Allowance.vue';
export default {
  components: {
    Bonus,
    Allowance,
  },
  data: () => ({
    tabIndex: null
  }),
  methods: {
    switchTab(data){
      this.$store.commit('additions/SET_CURRENT_TAB', data)
    },
    getAllTags(){
      this.$store.dispatch('additions/fetchAllTags')
    },
    fetchEmployeesIdsNames(){
      this.$store.dispatch('employees/fetchEmployeesIdsNames')
    }
  },
  computed: {
    currentTab() {
      return this.$store.getters['additions/currentTab']
    }
  },
  created() {
    this.$nextTick(() => {
      this.tabIndex = this.currentTab === 'bonus' ? 0 : 1
    })
    this.getAllTags()
    this.fetchEmployeesIdsNames()
  }
};
</script>