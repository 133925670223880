<template>
  <div>
    <v-snackbar :timeout="5000" :value="true" top :color="isError ? 'error' : 'success'" center v-model="snackBar"
      outlined elevation="24" text>
      {{ snackBarText }}
      <template v-slot:action="{ attrs }">
        <v-btn :color="isError ? 'error' : 'success'" text v-bind="attrs" @click="snackBar = false" data-cy="closeBtn">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="isloading">
      <spinner></spinner>
    </div>
    <!-- search -->
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field solo prepend-inner-icon="mdi-magnify" label="Search by Name" dense clearable flat color="primary"
          background-color="white lighten-4" v-model="query" @click:clear="resetData"
          @keyup.enter="searchBonus"></v-text-field>
      </v-col>

      <v-col class="mt-n3 ml-n3">
        <v-btn color="primary" @click.prevent="searchBonus" data-cy="searchBtn">Search</v-btn>
      </v-col>
    </v-row>
    <div class="text-right">
      <v-btn color="primary" dark class="mb-5 text-right" @click="addNewItem" data-cy="addBonus">
        ADD BONUS
      </v-btn>
    </div>
    <v-data-table fixed-header :headers="headers" :items="bonuses" class="elevation-1" :page.sync="page"
      @page-count="pageCount = $event" :items-per-page="itemsPerPage" hide-default-footer disable-sort>
      <template v-slot:top>
        <v-dialog v-model="dialog" max-width="600px" persistent>
          <v-form lazy-validation v-model="valid" @submit.prevent="submitBonus" ref="bonusForm">
            <v-card>
              <v-card-title class="text-h5 py-5 justify-center">
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select :items="tags" item-text="tag" item-value="tag" outlined dense
                        v-model="bonusForm.description" label="Select Bonus" :rules="[selectionRequired('Bonus Type')]"
                        data-cy="bonusSelect"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field outlined dense prefix="GH₵" v-model="bonusForm.totalAmount" label="Total Amount"
                        :rules="[
      required('Total Amount'),
      currencyFormat(bonusForm.totalAmount),
    ]" data-cy="amountText"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-menu ref="startMenu" v-model="startMenu" :close-on-content-click="false"
                        :return-value.sync="bonusForm.startDate" transition="scale-transition" offset-y
                        max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field outlined dense v-model="bonusForm.startDate" :rules="[required('Start Month')]"
                            label="Start Month" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                            data-cy="startDate"></v-text-field>
                        </template>
                        <v-date-picker v-model="bonusForm.startDate" type="month" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="startMenu = false" data-cy="cancelBtn">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.startMenu.save(bonusForm.startDate)"
                            data-cy="okBtn">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false"
                        :return-value.sync="bonusForm.endDate" transition="scale-transition" offset-y max-width="290px"
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :rules="[required('End Month')]" outlined dense v-model="bonusForm.endDate"
                            label="End Month" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                            data-cy="endDate"></v-text-field>
                        </template>
                        <v-date-picker v-model="bonusForm.endDate" type="month" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="endMenu = false" data-cy="cancelBtn">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.endMenu.save(bonusForm.endDate)" data-cy="okBtn">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <p class="my-0 py-0">Select Employee(s)</p>
                      <v-autocomplete :items="employeeIdsNames" item-text="displayName" item-value="employeeId" outlined
                        multiple dense v-model="bonusForm.employeeIds" :readonly="editMode" label="Select..."
                        :rules="[required('At Least One Employee')]" data-cy="employeeName">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.displayName }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text caption">
                            (+{{ bonusForm.employeeIds.length - 1 }}
                            others)
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" @click="dialog = false" data-cy="cancelBtn">
                  Cancel
                </v-btn>
                <v-btn color="primary darken-1" type="submit" data-cy="btnTitle">
                  {{ btnTitle }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary darken-1" @click="closeDelete" data-cy="cancelBtn">Cancel</v-btn>
              <v-btn color="primary darken-1" @click="deleteItemConfirm" data-cy="okBtn">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="primary" dense class="mr-2" @click="editItem(item)" data-cy="editBtn">
          mdi-pencil
        </v-icon>
        <v-icon color="primary" dense @click="deleteItem(item)" data-cy="deleteBtn">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-btn small :color="setButtonColor(item?.startDate, item?.endDate)" data-cy="progressBtn">
          {{ setProgress(item?.startDate, item?.endDate) }}
        </v-btn>
      </template>
      <template v-slot:[`item.no`]="{ index }">
        {{ index + 1 + pageNumbering }}
      </template>

      <template v-slot:[`item.displayName`]="{ item }">
        {{ item.displayName }}
      </template>

      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item.employeeId }}
      </template>

      <template v-slot:[`item.startDate`]="{ item }">{{
      getMoment(item.startDate)
    }}</template>

      <template v-slot:[`item.endDate`]="{ item }">{{
      getMoment(item.endDate)
    }}</template>

      <template v-slot:[`item.totalBonus`]="{ item }">{{
      formatFigure(item.totalBonus)
    }}</template>

      <template v-slot:[`item.monthlyBonus`]="{ item }">{{
      formatFigure(item.monthlyBonus)
    }}</template>

      <template v-slot:no-data> NO DATA AVAILABLE ! </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle
        data-cy="paginateBtn"></v-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import helpers from "../services/helpers";
import pageMixin from "../services/mixins/pageMixin";
import methodMixins from "../services/mixins/methodMixins";
import methMixin from "../services/mixins/methMixin";
import employeeMixin from "../services/mixins/employeeMixin";
import validation from "../services/validation";
import { createHeaders } from '../services/functions';
export default {
  mixins: [helpers, pageMixin, methodMixins, methMixin, employeeMixin],
  data: () => ({
    ...validation,
    valid: false,
    startMenu: false,
    endMenu: false,
    selectedItem: {},
    dialog: false,
    dialogDelete: false,
    isloading: false,
    isError: true,
    snackBar: false,
    snackBarText: "",
    itemsPerPage: 20,
    storePath: "additions",
    tagType: "Bonus",
    sourcePath: "additions",
    actionType: "fetchBonuses",
    //bonusItem: ["None", "Client Satisfaction"],
    query: null,
    editMode: false,
    bonusForm: new Form({
      id: null,
      startDate: "",
      endDate: "",
      monthlyAddition: null,
      totalAmount: null,
      description: null,
      employeeIds: [],
    }),

    headers: createHeaders("bonus"),
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    pageNumbering() {
      if (this.page === 0) {
        return 0;
      } else {
        return this.page * 20 - 20;
      }
    },
    formTitle() {
      return this.editMode ? "Edit Bonus" : "New Bonus";
    },
    btnTitle() {
      return this.editMode ? "Update" : "Add";
    },
    bonuses() {
      return this.$store.getters["additions/allBonuses"];
    },
    bonusCurrentPage: {
      get() {
        return this.$store.getters["additions/bonusCurrentPage"];
      },
      set(val) {
        this.$store.commit("additions/SET_BONUS_CURRENT_PAGE", val);
      },
    },
  },
  methods: {
    getEmployeeName(id) {
      return (
        this.employeeIdsNames.find((user) => {
          return user.employeeId === id;
        })?.displayName ?? null
      );
    },
    getMoment(date) {
      return moment(date).format("MMM YYYY");
    },

    getEmployeeNumber(id) {
      let employee = this.employeeIdsNames.find((user) => {
        return user.employeeId === id;
      });
      return employee ? employee.employeeNumber : null;
    },

    async initialize() {
      this.isloading = true;
      await this.$store.dispatch("additions/fetchBonuses", 1);
      this.isloading = false;
    },
    editItem(item) {
      let data = {
        id: item.id,
        startDate: item.startDate,
        endDate: item.endDate,
        monthlyAddition: item.monthlyBonus.toString(),
        totalAmount: item.totalBonus.toString(),
        description: item.description,
        employeeIds: [],
      };
      data.employeeIds.push(item.employeeId);
      this.dialog = true;
      this.bonusForm.fill(data);
      this.editMode = true;
    },

    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store
        .dispatch("additions/deleteBonus", this.selectedItem.id)
        .then(() => {
          this.selectedItem = {};
          this.closeDelete();
          this.isError = false;
          this.snackBarText = "Bonus Deleted Successfully";
          this.snackBar = true;
          this.initialize();
        })
        .catch(() => {
          this.selectedItem = {};
          this.closeDelete();
          this.isError = true;
          this.snackBarText = "Error Occurred While Deleting The Item";
          this.snackBar = true;
          this.initialize();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editMode = false;
        this.bonusForm.reset();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    async searchBonus() {
      if (this.query) {
        this.isloading = true;
        await this.$store.dispatch("additions/searchBonuses", this.query);
        this.isloading = false;
      } else {
        this.snackBar = true;
        this.snackBarText = " Search input cannot be empty";
      }
    },

    submitBonus() {
      // form validation
      if (!this.$refs.bonusForm.validate()) return;

      //amount validation against monthly and total value
      //get months difference
      let monthDiff = this.getMonthsDifference(
        this.bonusForm.startDate,
        this.bonusForm.endDate
      );

      this.bonusForm.monthlyAddition = this.bonusForm.totalAmount / monthDiff;
      this.isloading = true;
      let data = [];
      this.bonusForm.employeeIds.map((item) => {
        data.push({
          id: this.bonusForm.id,
          startDate: this.bonusForm.startDate,
          endDate: this.bonusForm.endDate,
          monthlyBonus: parseFloat(this.bonusForm.monthlyAddition),
          totalBonus: parseFloat(this.bonusForm.totalAmount),
          description: this.bonusForm.description,
          employeeId: item,
        });
      });
      this.dialog = false;
      if (this.editMode) {
        this.$store
          .dispatch("additions/editBonus", {
            ...data[0],
            displayName: this.getEmployeeName(data[0].employeeId),
          })
          .then(() => {
            this.isloading = false;
            this.snackBarText = "Bonus Updated Successfully";
            this.dialog = false;
            this.isError = false;
            this.snackBar = true;
            this.initialize();
          })
          .catch(() => {
            this.isloading = false;
            this.snackBarText = "Error Occurred While Updating Bonus";
            this.dialog = false;
            this.isError = true;
            this.snackBar = true;
          });
      } else {
        this.$store
          .dispatch("additions/createBonus", data)
          .then(() => {
            this.isloading = false;
            this.snackBarText = "Bonus Created Successfully";
            this.dialog = false;
            this.isError = false;
            this.snackBar = true;
            this.initialize();
          })
          .catch(() => {
            this.isloading = false;
            this.snackBarText = "Error Occurred While Creating Bonus";
            this.dialog = false;
            this.isError = true;
            this.snackBar = true;
          });
      }
    },

    addNewItem() {
      this.bonusForm.reset();
      this.editMode = false;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.col-12,
.col.sm-12,
.col-md-12 {
  padding: 0 10px !important;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
  font-size: 0.875rem;
  height: 64px;
}
</style>
